<template>
  <base-header>
    <div class="smp-nearLivePage-filterMenu">
      <near-live-match-day-select
        v-if="nearLiveGroupMode === 'day'" />

      <near-live-match-select
        v-else
        ref="matchSelect"
        @input="selectMatch" />

      <single-select-filter
        v-if="nearLiveGroupMode === 'match'"
        ref="viewModeSelect"
        :options="viewModeOptions"
        filter-key="viewMode"
        @input="changeViewMode"
        :icon="viewModeSelectIcon" />
    </div>
  </base-header>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import BaseHeader from '@/components/BaseHeader.vue';
import SingleSelectFilter from '@/components/SingleSelectFilter.vue';
import NearLiveMatchSelect from '@/components/near-live/NearLiveMatchSelect.vue';
import NearLiveMatchDaySelect from '@/components/near-live/NearLiveMatchDaySelect.vue';
import moment from '@/util/moment';

export default {
  components: {
    BaseHeader,
    SingleSelectFilter,
    NearLiveMatchSelect,
    NearLiveMatchDaySelect,
  },
  computed: {
    ...mapState('nearLive', ['match', 'viewMode']),
    ...mapGetters(['matches']),
    ...mapGetters('nearLive', ['nearLiveGroupMode']),
    kickoff() {
      if (this.match) {
        return moment(this.match.kickoff).toUserTimezone().format(moment.getUserDateTimeFormat());
      }

      return null;
    },
    viewModeSelectIcon() {
      return this.viewMode === 'grid' ? ['fas', 'th'] : ['fas', 'stream'];
    },
    viewModeOptions() {
      return [
        {
          value: 'grid',
          label: this.$t('nearLive.viewModes.grid'),
          active: this.viewMode === 'grid',
          icon: ['fas', 'th'],
        },
        {
          value: 'timeline',
          label: this.$t('nearLive.viewModes.timeline'),
          active: this.viewMode === 'timeline',
          icon: ['fas', 'stream'],
        },
      ];
    },
  },
  beforeDestroy() {
    this.unbindPostings();
  },
  methods: {
    ...mapActions('nearLive', ['setMatch', 'unbindPostings']),
    selectMatch(matchOption) {
      this.setMatch(this.matches.find((m) => m.id === matchOption.value));
      this.$refs.matchSelect.close();
    },
    changeViewMode(mode) {
      this.$store.dispatch('nearLive/setViewMode', mode.value);
      this.$refs.viewModeSelect.close();
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-nearLivePage-filterMenu {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-bottom: @space-2;

    &:not(:last-child) {
      margin-right: @space-2;
    }
  }
}
</style>
