<template>
  <single-select-filter
    v-if="options"
    ref="viewModeSelect"
    :options="options"
    filter-key="nearLiveMatchDay"
    @input="changeMatchDay"
    :icon="['fas', 'futbol']" />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from '@/util/moment';
import SingleSelectFilter from '@/components/SingleSelectFilter.vue';

export default {
  components: { SingleSelectFilter },
  computed: {
    ...mapState('nearLive', ['matchDay']),
    ...mapGetters(['allMatchDays']),
    ...mapGetters('nearLive', ['nearLiveGroupMode']),
    options() {
      return this.allMatchDays.map((day) => ({
        value: day,
        label: moment(day).format(moment.getUserDateFormat()),
        active: day === this.matchDay,
      }));
    },
  },
  methods: {
    ...mapActions('nearLive', ['setMatchDay']),
    changeMatchDay(day) {
      this.setMatchDay(day.value);
    },
  },
};
</script>
